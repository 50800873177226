import React from "react";
import { GeoAlt, Eye, Dot } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function Card(props) {
  function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <div className="card">
        {props.image && (
          <div style={{ height: props.imageHeight ? props.imageHeight : "212px" }} className={!props.isImage && props.page === "property" ? "hidden-xs hidden-xss" : ""}>
            <Link
              onClick={goToTop}
              to={props.detail_url ? props.detail_url : "/"}
              className={props.align}
              style={{
                overflow: "hidden",
                display: "flex",
                height: "100%",
                justifyContent: props.justifycontent,
              }}
            >
              {props.page && props.page === "property" && (
                <div
                  style={{
                    position: "relative", // Required for child elements positioning
                    width: "100%", // Adjust the width as needed
                    height: props.imageHeight ? props.imageHeight : "212px",
                    zIndex: "1",
                    textAlign: "center",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "lightgray 50%",
                      backgroundImage: `url(${props.image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      filter: "blur(50px)", // Adjust the blur value as needed
                      zIndex: "-1",
                    }}
                  />
                  <img
                    src={props.image}
                    alt={props.title}
                    style={{
                      width: "auto",
                      height: props.imageHeight ? props.imageHeight : "212px",
                    }}
                  />
                </div>
              )}
              {props.page && props.page !== "property" && (
                <img
                  className="img-fluid"
                  src={props.image}
                  alt=""
                  style={{
                    maxWidth: props.imgaeWidth ? props.imgaeWidth : "",
                    maxHeight: props.imageHeight ? props.imageHeight : "",
                  }}
                />
              )}
            </Link>
          </div>
        )}
        <div className={props.RemoveBodyPaddingBottom ? "remove-body-padding-bottom card-body" : "card-body"}>
          <h6
            className="card-title mt-2"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className={"text-decoration-none"}>
              {props.title ? props.title : ""}
            </Link>
          </h6>
          <p className="card-subtitle">
            <GeoAlt color="#0040c1" />
            &nbsp;
            {props.address1 ? `${props.address1} , ${props.address2}` : ""}
            {props.address1 && <br />}
            &nbsp;{props.location ? props.location : ""}
            {props.postalcode ? ` - ${props.postalcode}` : ""}
          </p>
          <p>
            {props.category_id && (
              <Link to={`/search?category=${props.category_id}&location=1&page=1&limit=10`}>
                <span className="badge rounded-pill card-business">
                  <Dot size={30} />
                  {props.type ? props.type : ""}
                </span>
              </Link>
            )}
            {!props.category_id && (
              <span className="badge rounded-pill card-business">
                <Dot size={30} />
                {props.type ? props.type : ""}
              </span>
            )}

            {props.salary && <span className="badge rounded-pill salary-pill">&nbsp;{`£${props.salary}`}</span>}
          </p>
          {props.desc && (
            <p
              className="card-text"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {props.desc ? props.desc : ""}
            </p>
          )}
        </div>
        <div className="card-footer bg-transparent border-1">
          <p className="card-footer-content d-flex justify-content-between align-items-center" style={{ margin: "0px" }}>
            <small className="card-views">
              <Eye size={18} />
              &nbsp;&nbsp;{props.views}&nbsp;views
            </small>
            <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className="btn btn-sm btn-md float-end text-center card-btn-view-detail">
              View details
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
export default Card;
