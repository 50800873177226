import axios from "axios";
import React, { useEffect, useState } from "react";

export default function CustomAdd() {
  const [exchangeRate, setExchangeRate] = useState();
  useEffect(() => {
    getExchangeRate();
  }, []);

  async function getExchangeRate() {
    await axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + "/api/advertisements",
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setExchangeRate(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        {exchangeRate &&
          exchangeRate.map((element, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12 card-holder">
                <div className="card card-money-exchange">
                  <div className="card-body card-body-money-exchange">
                    <div className="row">
                      <div className="col-2">
                        <img className="img-fluid" src={element.image_path} alt={element.name} />
                      </div>
                      <div className="col-10">
                        <h1 className="live-exchange-rate-text">{element.name}</h1>
                        {/* <h1 className="live-exchange-rate-text">Peepal Has Your Back</h1> */}
                      </div>
                    </div>

                    <p className="live-exchange-rate">{element.detail}</p>
                    <a href={element.link} target="_blank" rel="noopener noreferrer" className="btn btn-send-money">
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
